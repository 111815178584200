import React from "react"
import BlogLink from "../components/BlogLink/BlogLink"
import ColorDots from "../components/ColorDots/ColorDots"
import EmailSignup from "../components/EmailSignup/EmailSignup"
import Footer from "../components/Footer/Footer"
import Layout from "../components/layout"

import styles from "./blogStyles.module.scss"

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge, i) => (
      <div
        className={[
          styles.blogLink,
          i % 2 === 0 ? styles.even : styles.odd,
        ].join(" ")}
        key={edge.node.id}
      >
        <BlogLink post={edge.node} style={{ margin: "40px" }}></BlogLink>
      </div>
    ))

  return (
    <Layout>
      <div style={{ margin: "100px auto 0 auto" }}>
        <h1>Recent Posts</h1>
        <ColorDots style={{ margin: "40px auto" }}></ColorDots>
        <div className={styles.blogLinksWrapper}>{Posts}</div>
      </div>
      <EmailSignup></EmailSignup>
      <Footer></Footer>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
          featuredImg {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
