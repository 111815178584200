import React from "react"
import Link from "../Link/Link"
import Img from "gatsby-image"

import styles from "./blogLinkStyles.module.scss"

const BlogLink = ({ post, className, style }) => {
  console.log(post)
  return (
    <div className={className} style={{ ...style, display: "inline-block" }}>
      <Link to={post.frontmatter.slug} className={styles.link}>
        <div className={styles.image}>
          <Img fluid={post.featuredImg?.childImageSharp?.fluid}></Img>
        </div>
        <h2 className={styles.linkText}>{post.frontmatter.title}</h2>
      </Link>
    </div>
  )
}

export default BlogLink
